









































import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CalculatedPricesTooltip from '@/modules/cars/components/screenshot-popup/calculated-prices-tooltip.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import PercentFilter from '@/modules/common/filters/percent.filter';
import Day from '@/modules/common/types/day.type';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import { Inject } from 'inversify-props';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Screenshot from './screenshot.vue';

@Component({
    components: {
        ModalWrapper,
        Screenshot,
        CalculatedPricesTooltip,
    },
    filters: { PercentFilter },
})
export default class ScreenshotPopup extends Vue {
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    @Prop({
        type: String,
    })
    private url?: string;

    @Prop({
        type: String,
    })
    private date?: string;

    @Prop({
        type: String,
    })
    rectangleUniqueness?: string;

    @Prop({
        type: String,
    })
    private provider!: string;

    getDate(date: Date | null) {
        if (!date) {
            return '';
        }
        const parsedDate = new Date(date);
        return moment(parsedDate).utc().format('DD-MMM-YYYY | HH:mm');
    }

    get isCalculatedPrice() {
        const { extraExpenses } = this.carsFiltersService.settings;
        const { priceShown } = this.carsService;
        return priceShown === PRICE_SHOWN.CALCULATED && extraExpenses.length;
    }

    get pickUpCityCode() {
        const { pickUpCityCode } = this.carsService.storeState.settings;
        return pickUpCityCode;
    }

    get location() {
        const { locations } = this.carsFiltersService.settings;
        const { pickUpCityCode } = this.carsService.storeState.settings;

        if (!locations || !pickUpCityCode) {
            return null;
        }

        const currentLocation = locations.find(location => location.locationId === pickUpCityCode);

        if (!currentLocation) {
            return null;
        }

        return currentLocation.locationName;
    }

    get day(): Day {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    get competitorPercent() {
        return this.carsService.competitorPercent(this.day);
    }
}













import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Screenshot extends Vue {
    @Prop({
        type: String,
        required: true,
    })
    title!: string;

    @Prop({
        type: String,
        default: null,
    })
    src!: string | null;

    @Prop({
        type: String,
        default: null,
    })
    rectangleUniqueness!: string | null;

    mounted() {
        if (!this.src) {
            this.$router.back();
        }
    }

    isImageURL(url: string) {
        if (!url) {
            return false;
        }

        return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }

    getOffset(el: Element) {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY,
        };
    }

    onLoadIFrame() {
        const iframe = this.$refs[`${this.title}-iframe`] as HTMLIFrameElement;
        if (iframe && this.rectangleUniqueness) {
            const doc = iframe.contentWindow;
            if (doc) {
                doc.postMessage(this.rectangleUniqueness, '*');
            }
        }
    }
}
